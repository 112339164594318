<script setup>
</script>

<template>
  <crud-details-page
    api="arithmetic-run-obstacle"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
        <!-- Type -->
        <crud-field-select
          api-field-name="Type"
          options-enum="enums.ArithmeticRunObstacle"
          required
        />
        <!-- Asset -->
        <crud-field-selector-files api-field-name="Asset" />
      </fields-col>
      <fields-col :sm="8">
        <!-- SpawnPositionX -->
        <crud-field-number
          api-field-name="SpawnPositionX"
          :min="0"
          :max="1"
          :precision="2"
        />
        <!-- SpeedX -->
        <crud-field-number
          api-field-name="SpeedX"
          :precision="2"
          :min="-1000000"
        />
        <!-- SpeedY -->
        <crud-field-number
          api-field-name="SpeedY"
          :precision="2"
          :min="-1000000"
        />
        <!-- ActiveOnlyWhenInCamera -->
        <crud-field-switcher
          api-field-name="ActiveOnlyWhenInCamera"
        />
      </fields-col>
    </template>
  </crud-details-page>
</template>
