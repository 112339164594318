<script setup>
</script>

<template>
  <crud-details-page
    api="arithmetic-run"
    disable-top-margin
  >
    <template #form="{ form }">
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <section-divider>General settings</section-divider>
      <fields-col :sm="8">
        <!-- Stages -->
        <crud-field-text
          api-field-name="Stages"
        />
        <!-- Length -->
        <crud-field-number
          api-field-name="Length"
          :min="0"
          :precision="2"
        />
        <!-- Width -->
        <crud-field-number
          api-field-name="Width"
          :min="0"
          :precision="2"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- FinishLineDistanceOffset -->
        <crud-field-number
          api-field-name="FinishLineDistanceOffset"
          :min="0"
          :precision="2"
        />
        <!-- ShowScore -->
        <crud-field-switcher api-field-name="ShowScore" />
        <!-- ContinueMovementAfterFinish -->
        <crud-field-switcher api-field-name="ContinueMovementAfterFinish" />
        <!-- IsTutorial -->
        <crud-field-switcher api-field-name="IsTutorial" />
        <crud-field-switcher api-field-name="IsOnboarding" />
      </fields-col>
      <section-divider>Player settings</section-divider>
      <fields-col :sm="8">
        <!-- PlayerUnityAsset -->
        <crud-field-number
          api-field-name="PlayerUnityAsset"
          :min="0"
        />
        <!-- PlayerAsset -->
        <crud-field-selector-files
          api-field-name="PlayerAsset"
        />
        <!-- PlayerSpawnPositionX -->
        <crud-field-number
          api-field-name="PlayerSpawnPositionX"
          :min="0"
          :max="1"
          :precision="2"
        />
        <!-- PlayerSpawnPositionY -->
        <crud-field-number
          api-field-name="PlayerSpawnPositionY"
          :min="0"
          :precision="2"
        />
      </fields-col>
      <fields-col :sm="8">
        <!-- PlayerInitialScore -->
        <crud-field-number
          api-field-name="PlayerInitialScore"
          :min="0"
          :precision="2"
        />
        <!-- PlayerSpeed -->
        <crud-field-number
          api-field-name="PlayerSpeed"
          :min="0"
          :precision="2"
        />
        <!-- PlayerAcceleration -->
        <crud-field-number
          api-field-name="PlayerAcceleration"
          :min="0"
          :precision="2"
        />
        <!-- PlayerDeceleration -->
        <crud-field-number
          api-field-name="PlayerDeceleration"
          :min="0"
          :precision="2"
        />
      </fields-col>
      <section-divider>Obstacles</section-divider>
      <fields-col :sm="24">
        <template
          v-for="i in 20"
          :key="i"
        >
          <section-divider>Obstacle {{ i }}</section-divider>
          <fields-col :sm="6">
            <!-- Type -->
            <crud-field-selector-arithmetic-run-obstacle :api-field-name="`Obstacle${i}.Obstacle`" />
            <!-- SpawnPositionY -->
            <crud-field-number
              :api-field-name="`Obstacle${i}.SpawnPositionY`"
              :min="0"
              :precision="2"
            />
          </fields-col>
          <fields-col
            v-if="form[`Obstacle${i}`]?.Obstacle?.AdminLabel?.includes('ArithmeticGate')"
            :sm="6"
          >
            <!-- Value1 -->
            <crud-field-number
              :api-field-name="`Obstacle${i}.Value1`"
              label="Left Value"
              :min="-1000000"
              :precision="2"
            />
            <!-- Value2 -->
            <crud-field-number
              :api-field-name="`Obstacle${i}.Value2`"
              label="Right Value"
              :min="-1000000"
              :precision="2"
            />
          </fields-col>
          <fields-col
            v-if="form[`Obstacle${i}`]?.Obstacle?.AdminLabel?.includes('ArithmeticGate')"
            :sm="6"
          >
            <!-- Operation1 -->
            <crud-field-select
              :api-field-name="`Obstacle${i}.Operation1`"
              label="Left Operation"
              options-enum="enums.ArithmeticRunGateOperation"
            />
            <!-- Operation2 -->
            <crud-field-select
              :api-field-name="`Obstacle${i}.Operation2`"
              label="Right Operation"
              options-enum="enums.ArithmeticRunGateOperation"
            />
          </fields-col>
          <fields-col
            v-if="form[`Obstacle${i}`]?.Obstacle?.AdminLabel?.includes('ThresholdEncounter')"
            :sm="6"
          >
            <!-- Value1 -->
            <crud-field-number
              :api-field-name="`Obstacle${i}.Value1`"
              label="Damage"
              :min="0"
              :precision="2"
            />
          </fields-col>
          <fields-col
            v-if="form[`Obstacle${i}`]?.Obstacle?.AdminLabel?.includes('DamageOverTimeField')"
            :sm="6"
          >
            <!-- Value1 -->
            <crud-field-number
              :api-field-name="`Obstacle${i}.Value1`"
              label="Damage over time"
              :min="0"
              :precision="2"
            />
            <!-- Value2 -->
            <crud-field-number
              :api-field-name="`Obstacle${i}.Value2`"
              label="Slow %"
              :min="0"
              :max="100"
              :precision="2"
            />
          </fields-col>
        </template>
      </fields-col>
    </template>
  </crud-details-page>
</template>
